<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-16 mb-0">
                  {{ dataSchoolYear.semester === "Genap" ? "2nd" : "1st" }}
                  Semester
                </h2>
                <h5 style="color: #aeaeae" class="font-weight-normal mt-2">
                  {{ dataSchoolYear.tahun || "" }}
                </h5>
              </div>
              <div>
                <a-tag
                  :color="dataSchoolYear.status === 'aktif' ? 'green' : 'red'"
                  >{{
                    dataSchoolYear.status === "aktif" ? "active" : "inactive"
                  }}</a-tag
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu
              :menus="menus"
              :menuActive="menuActive"
              @menu-change="menuChange"
            />
          </div>
          <div class="card-footer d-flex align-items-center">
            <!-- <a-icon class="mr-1" type="double-left" /> -->
            <router-link :to="{ name: 'Master Admin' }"
              >Back to Master Data</router-link
            >
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div>
                <h2 class="font-size-18">{{ menuName }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1 && dataSchoolYear.id">
              <ManageClass :dataSchoolYear="dataSchoolYear" />
            </template>
            <template v-if="menuActive === 2">
              <TableTeacher :idTahunAjaran="idSchoolYear" />
            </template>
            <template v-if="menuActive === 3">
              <Extracurricular />
            </template>
            <template v-if="menuActive === 4">
              <ManageSchoolYear
                :dataSchoolYear="dataSchoolYear"
                @fetchDetail="fetchDetail"
              />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const ManageClass = () => import('@/views/Admin/DetailSchoolYear/ManageClass')
const ManageSchoolYear = () => import('@/components/app/Admin/SchoolYear/Manage')
const TableTeacher = () => import('@/views/Admin/DetailSchoolYear/TableTeacherSchedule')
const Extracurricular = () => import('@/views/Admin/DetailSchoolYear/Extracurricular')
const menus = [
  {
    key: 1,
    menu: 'Student Class',
  },
  {
    key: 2,
    menu: 'Schedules',
  },
  {
    key: 3,
    menu: 'Extracurricular Spv.',
  },
  {
    key: 4,
    menu: 'Manage',
  },
]

export default {
  data () {
    return {
      idSchoolYear: null,
      dataSchoolYear: {},
      menus,
      menuActive: 1,
    }
  },
  components: { Menu, ManageSchoolYear, TableTeacher, ManageClass, Extracurricular },
  methods: {
    async fetchDetail() {
      const data = await this.$store.dispatch('schoolYear/FETCH_DETAIL_SCHOOL_YEAR', { idSchoolYear: this.idSchoolYear })
      this.dataSchoolYear = data
    },
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
  async created() {
    this.idSchoolYear = Number(this.$route.params.id)
    this.fetchDetail()
  },
}
</script>

<style>
